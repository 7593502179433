/* global Telegram */
import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [profilePhoto, setProfilePhoto] = useState(null);

  useEffect(() => {
    // Fetch Telegram user profile photo
    if (Telegram.WebApp.initDataUnsafe?.user?.photo_url) {
      // setProfilePhoto(Telegram.WebApp.initDataUnsafe.user.photo_url);
    }

    // Expand the WebApp to maximum height
    if (Telegram.WebApp.expand) {
      Telegram.WebApp.expand();
    }


    
    // Disable vertical swipes to prevent accidental closing
    if (Telegram.WebApp.disableVerticalSwipes) {
      Telegram.WebApp.disableVerticalSwipes();
    }

    // Load images data
    fetch('/images-data.json')
      .then((response) => response.json())
      .then((data) => {
        setImages(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Ошибка загрузки данных:', error);
        setLoading(false);
      });

    // Cleanup: Enable vertical swipes when the component unmounts
    return () => {
      if (Telegram.WebApp.enableVerticalSwipes) {
        Telegram.WebApp.enableVerticalSwipes();
      }
    };
  }, []);

  const handleImageClick = (image) => {
    Telegram.WebApp.openLink(image.websiteUrl);
  };

  return (
    <div className="app">
      <header>
        <h1>Выбери свой бонус</h1>
        {profilePhoto && (
          <div className="profile-icon">
            <img src={profilePhoto} alt="Profile" className="profile-icon-image" />
          </div>
        )}
      </header>

      {loading ? (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div style={{ marginTop: '-40px' }} className="image-gallery"> {/* Reduce margin to move closer to header */}
          {images.map((image, index) => (
            <div
              className="image-card"
              key={index}
              onClick={() => handleImageClick(image)}
              style={{ cursor: 'pointer' }}
            >
              <div
                className="image-wrapper"
                style={{ position: 'relative', borderRadius: '12px', overflow: 'hidden' }}
              >
                <img
                  src={`/images/backgrounds/${image.filename}`}
                  alt={image.description || 'Изображение'}
                  className="image"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
                <div
                  style={{
                    position: 'absolute',
                    bottom: '2px',
                    left: '2px',
                    right: '2px',
                    background: 'rgba(0, 0, 0, 0.5)', // Полупрозрачный фон
                    backdropFilter: 'blur(5px)',
                    WebkitBackdropFilter: 'blur(8px)',
                    borderRadius: '10px',
                    padding: '5px', // Уменьшенный padding
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {/* Иконка слева */}
                  <img
                    src={`/images/icons/${image.icon || 'default-icon.png'}`}
                    alt="Casino Icon"
                    style={{
                      width: '30px', // Уменьшенная ширина
                      height: '30px', // Уменьшенная высота
                      borderRadius: '8px',
                      objectFit: 'cover',
                      marginRight: '8px',
                    }}
                  />

                  {/* Контент по центру */}
                  <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    {/* Название и рейтинг */}
                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                      <span style={{ color: '#fff', fontWeight: 'bold', fontSize: '11px' }}>
                        {image.title}
                      </span>
                      <span style={{ color: '#00FF77', fontWeight: 'bold', fontSize: '9px' }}>
                        ★ {image.rating || '5.0'}
                      </span>
                    </div>

                    {/* Бонусы */}
                    <div style={{ display: 'flex', gap: '4px', marginTop: '3px' }}>
                      <span
                        style={{
                          background: 'rgba(255, 215, 0, 0.21)', // Полупрозрачный фон
                          color: 'rgb(255, 215, 0)',
                          fontWeight: 'bold',
                          fontSize: '10px',
                          padding: '2px 6px',
                          borderRadius: '15px',
                        }}
                      >
                        {image.bonus || '500%'}
                      </span>
                      <span
                        style={{
                          background: 'rgba(146, 37, 255, 0.21)', // Полупрозрачный фон
                          color: 'rgb(169, 84, 255)',
                          fontWeight: 'bold',
                          fontSize: '10px',
                          padding: '2px 6px',
                          borderRadius: '15px',
                        }}
                      >
                        {image.freeSpins || '70 FS'}
                      </span>
                    </div>
                  </div>

                  {/* Стрелка справа */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    style={{ marginLeft: '8px', color: '#fff' }}
                  >
                    <path
                      d="M7.5 15L12.5 10L7.5 5"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default App;